import React from 'react'


var CLIENT_ID = "388614345690-miqpeovu4gln373sha4qvg5e9d8ks5c8.apps.googleusercontent.com"
var API_KEY = "AIzaSyC9LipL3iiaYFK95e3CH_-FGZyTATftEjk"

var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
var SCOPES = "https://www.googleapis.com/auth/calendar.events"
var calendarId = "furryuscalendar@gmail.com"

export default function CalendarTest() {
  return (
    <div>
          {/* Calendar Section */}
          <div className="w-full bg-white shadow flex flex-col my-4 p-6">
              <p className="text-xl font-semibold pb-5">So Cal Event Calendar!!</p>
              <iframe
                  title="Furry Events Calendar"
                  src="https://calendar.google.com/calendar/embed?src=furryuscalendar%40gmail.com&ctz=UTC"
                  className="w-full h-96 border"
                  style={{ height: '700px', width: '100%' }}
                  frameBorder="0"
              ></iframe>
          </div>

    </div>
  )
}
